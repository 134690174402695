@use "base/mixins" as *;

.widget-header {
  display: grid;
  grid-template-areas: "header-logo header-nav";
  grid-template-columns: auto 1fr;
  gap: 0 20px;
  align-items: center;
  width: min(100vw - 40px, 1080px);
  margin: auto;
  padding: var(--v-offset-lt) 0;

  @include min(tablet-landscape) {
    grid-template-areas: "header-left header-logo header-nav";
    grid-template-columns: 1fr auto 1fr;
    padding: var(--v-offset-sm) 0;
  }

  &__logo {
    grid-area: header-logo;

    .svg-icon {
      filter:
              drop-shadow(0 0 0.75em var(--c-oxford-blue))
              drop-shadow(0 0 0.5em var(--c-oxford-blue))
              drop-shadow(0 0 0.25em var(--c-oxford-blue));
    }
  }

  &__nav {
    grid-area: header-nav;
  }
}
