.btn {
  all: unset;
  box-sizing: border-box;
  margin: 0;
  padding: 0 var(--v-offset-md);
  text-decoration: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-grid;
  place-content: center;

  &--green {
    height: 62px;
    background-image: linear-gradient(to bottom, #00B147, #008E39);
    border-radius: 4px;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
  }

  &--link {
    text-decoration: underline;
    font-size: 14px;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
