@use "base/mixins" as *;

.maybe-nav {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  font-size: 10px;
  line-height: 1;
  transform: translateY(3px);

  @include min(tablet-portrait) {
    font-size: 14px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}
