@use "base/mixins" as *;

.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &--page-background {
    position: absolute;
    inset: 0 0 auto 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 600px;

    img {
      display: block;
      object-fit: contain;
      object-position: center top;
    }

    @include min(tablet-portrait) {
      z-index: -1;
      width: clamp(1520px, 100vw, 2000px);

      img {
        object-fit: contain;
      }
    }
  }
}
