.banner-main {
  position: relative;
  z-index: 0;
  width: min(100vw - 40px, 640px);
  margin: auto;
  display: grid;
  justify-items: center;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: auto;
    background-image: linear-gradient(to right, #03D9FF, #029923);
    border-radius: 2.8vw;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 4px;
    z-index: -1;
    margin: auto;
    background-image: url("../images/webp/welcome_bonus_bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: var(--c-oxford-blue);
    border-radius: calc(2.8vw - 4px);
  }

  .text-fitting {
    display: block;
    height: auto;

    text {
      font-weight: bold;
      text-transform: uppercase;
    }

    &--num {
      max-width: 85%;

      text {
        fill: var(--c-goldenrod);
      }
    }

    &--text {
      max-width: 50%;
      transform: translateY(-25%);

      text {
        fill: var(--c-white);
      }
    }
  }
}
