@use "base/mixins" as *;

.widget-main {
  display: grid;
  place-content: center;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-template-areas:
    "title"
    "banner"
    "button"
    "slogan"
    "text";
  width: min(100vw - 40px, 800px);
  margin: auto;

  @include min(tablet-portrait) {
    grid-template-areas:
    "title"
    "slogan"
    "banner"
    "button"
    "text";
  }

  &__title {
    grid-area: title;
    width: min(100%, 640px);

    @include max(tablet-portrait) {
      margin-top: 260px;
    }

    .display-large {
      margin: 0 0 min(10px, 0.6vmax);
      font-size: 9em;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      color: var(--c-malachite);
    }
  }

  &__slogan {
    grid-area: slogan;
    padding-bottom: var(--v-offset-sm);

    .headline-large {
      margin: 0;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      filter:
        drop-shadow(0 0 0.25em var(--c-oxford-blue))
        drop-shadow(0 0 0.25em var(--c-oxford-blue));
    }
  }

  &__banner {
    grid-area: banner;
    padding-bottom: var(--v-offset-sm);
  }

  &__button {
    grid-area: button;
    display: flex;
    flex-direction: column;
    padding-bottom: var(--v-offset-sm);

    .btn:not(:last-child) {
      margin-bottom: min(15px, 0.9vmax);
    }
  }

  &__text {
    grid-area: text;
    padding-bottom: var(--v-offset-sm);
  }
}
