.widget-footer {
  width: min(100vw - 40px, 1280px);
  margin: auto;
  padding-bottom: var(--v-offset-md);
  text-align: center;

  .body-small {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }
}
