:root {
  // z-orders
  --z-index-header: 1;

  // colors
  --c-white: rgb(255 255 255);
  --c-oxford-blue: rgb(18 29 65);
  --c-malachite: rgb(1 236 95);
  --c-goldenrod: rgb(236 201 1);
  --c-deep-navy: rgb(0 9 47);

  // NPM package typographics
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Oswald", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;

  // offsets
  --v-offset-lt: min(20px, 1.2vmax);
  --v-offset-sm: min(40px, 2.4vmax);
  --v-offset-md: min(80px, 4.8vmax);
  --v-offset-lg: min(160px, 9.6vmax);
}
