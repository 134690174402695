html,
body {
  position: relative;
  overflow-x: hidden;
}

body {
  max-width: 100vw;
  background-color: var(--c-oxford-blue);
  color: var(--c-white);

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
}
