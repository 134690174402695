:root {
  --max-vw: 1440;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .75;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc((var(--min-vw) / 10) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / ((var(--max-vw) / 10)  - (var(--min-vw) / 10)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-base, "Inter");
  font-size: var(--font-size-clamp);
  line-height: var(--line-height-base);
  font-weight: 400;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

:where(:not(html, iframe, canvas, img, svg, video, audio, svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

a, button {
  cursor: revert;
}

ol, ul, menu, summary {
  list-style: none;
}

ol {
  counter-reset: revert;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
  user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
  user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

::-webkit-details-marker {
  display: none;
}

:root {
  --z-index-header: 1;
  --c-white: #fff;
  --c-oxford-blue: #121d41;
  --c-malachite: #01ec5f;
  --c-goldenrod: #ecc901;
  --c-deep-navy: #00092f;
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Oswald", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
  --v-offset-lt: min(20px, 1.2vmax);
  --v-offset-sm: min(40px, 2.4vmax);
  --v-offset-md: min(80px, 4.8vmax);
  --v-offset-lg: min(160px, 9.6vmax);
}

html, body {
  position: relative;
  overflow-x: hidden;
}

body {
  background-color: var(--c-oxford-blue);
  max-width: 100vw;
  color: var(--c-white);
}

@supports (min-height: 100dvh) {
  body {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  body {
    min-height: 100vh;
  }
}

@media (width <= 1199.98px) {
  [data-device="max-tablet-landscape"] {
    display: none;
  }
}

@media (width >= 1200px) {
  [data-device="min-tablet-landscape"] {
    display: none;
  }
}

@media (width <= 991.98px) {
  [data-device="max-tablet-portrait"] {
    display: none;
  }
}

@media (width >= 992px) {
  [data-device="min-tablet-portrait"] {
    display: none;
  }
}

@media (width <= 767.98px) {
  [data-device="max-phone-landscape"] {
    display: none;
  }
}

@media (width >= 768px) {
  [data-device="min-phone-landscape"] {
    display: none;
  }
}

@media (width <= 575.98px) {
  [data-device="max-phone-portrait"] {
    display: none;
  }
}

@media (width >= 576px) {
  [data-device="min-phone-portrait"] {
    display: none;
  }
}

@media (width <= 1200px) and (any-pointer: coarse) {
  [data-device="max-touch"] {
    display: none;
  }
}

@media (width >= 1200px) and (any-pointer: fine) {
  [data-device="min-touch"] {
    display: none;
  }
}

.widget-header {
  width: min(100vw - 40px, 1080px);
  padding: var(--v-offset-lt) 0;
  grid-template-columns: auto 1fr;
  grid-template-areas: "header-logo header-nav";
  align-items: center;
  gap: 0 20px;
  margin: auto;
  display: grid;
}

@media (width >= 1200px) {
  .widget-header {
    padding: var(--v-offset-sm) 0;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: "header-left header-logo header-nav";
  }
}

.widget-header__logo {
  grid-area: header-logo;
}

.widget-header__logo .svg-icon {
  filter: drop-shadow(0 0 .75em var(--c-oxford-blue)) drop-shadow(0 0 .5em var(--c-oxford-blue)) drop-shadow(0 0 .25em var(--c-oxford-blue));
}

.widget-header__nav {
  grid-area: header-nav;
}

.widget-main {
  grid-template-columns: 1fr;
  grid-template-areas: "title"
                       "banner"
                       "button"
                       "slogan"
                       "text";
  place-content: center;
  justify-items: center;
  width: min(100vw - 40px, 800px);
  margin: auto;
  display: grid;
}

@media (width >= 992px) {
  .widget-main {
    grid-template-areas: "title"
                         "slogan"
                         "banner"
                         "button"
                         "text";
  }
}

.widget-main__title {
  grid-area: title;
  width: min(100%, 640px);
}

@media (width <= 991.98px) {
  .widget-main__title {
    margin-top: 260px;
  }
}

.widget-main__title .display-large {
  text-transform: uppercase;
  text-align: center;
  color: var(--c-malachite);
  margin: 0 0 min(10px, .6vmax);
  font-size: 9em;
  font-weight: 600;
}

.widget-main__slogan {
  padding-bottom: var(--v-offset-sm);
  grid-area: slogan;
}

.widget-main__slogan .headline-large {
  text-transform: uppercase;
  text-align: center;
  filter: drop-shadow(0 0 .25em var(--c-oxford-blue)) drop-shadow(0 0 .25em var(--c-oxford-blue));
  margin: 0;
  font-weight: 500;
}

.widget-main__banner {
  padding-bottom: var(--v-offset-sm);
  grid-area: banner;
}

.widget-main__button {
  padding-bottom: var(--v-offset-sm);
  flex-direction: column;
  grid-area: button;
  display: flex;
}

.widget-main__button .btn:not(:last-child) {
  margin-bottom: min(15px, .9vmax);
}

.widget-main__text {
  padding-bottom: var(--v-offset-sm);
  grid-area: text;
}

.widget-footer {
  width: min(100vw - 40px, 1280px);
  padding-bottom: var(--v-offset-md);
  text-align: center;
  margin: auto;
}

.widget-footer .body-small {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
}

.svg-icon--logo-crasher {
  aspect-ratio: 300 / 68;
  width: clamp(140px, 30vw, 220px);
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container picture {
  width: 100%;
  height: 100%;
  display: block;
}

.image-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.image-container--page-background {
  z-index: -1;
  width: 600px;
  position: absolute;
  inset: 0 0 auto 50%;
  transform: translateX(-50%);
}

.image-container--page-background img {
  object-fit: contain;
  object-position: center top;
  display: block;
}

@media (width >= 992px) {
  .image-container--page-background {
    z-index: -1;
    width: clamp(1520px, 100vw, 2000px);
  }

  .image-container--page-background img {
    object-fit: contain;
  }
}

.article-main {
  width: min(100%, 680px);
  padding: var(--v-offset-sm);
  background-color: var(--c-deep-navy);
  box-shadow: 0 0 0 1px inset var(--c-malachite);
  border-radius: 2.8vw;
  margin: auto;
}

.banner-main {
  z-index: 0;
  justify-items: center;
  width: min(100vw - 40px, 640px);
  margin: auto;
  display: grid;
  position: relative;
}

.banner-main:before {
  content: "";
  z-index: -1;
  background-image: linear-gradient(to right, #03d9ff, #029923);
  border-radius: 2.8vw;
  margin: auto;
  position: absolute;
  inset: 0;
}

.banner-main:after {
  content: "";
  z-index: -1;
  background-image: url("welcome_bonus_bg.2e250e72.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--c-oxford-blue);
  border-radius: calc(2.8vw - 4px);
  margin: auto;
  position: absolute;
  inset: 4px;
}

.banner-main .text-fitting {
  height: auto;
  display: block;
}

.banner-main .text-fitting text {
  text-transform: uppercase;
  font-weight: bold;
}

.banner-main .text-fitting--num {
  max-width: 85%;
}

.banner-main .text-fitting--num text {
  fill: var(--c-goldenrod);
}

.banner-main .text-fitting--text {
  max-width: 50%;
  transform: translateY(-25%);
}

.banner-main .text-fitting--text text {
  fill: var(--c-white);
}

.btn {
  all: unset;
  box-sizing: border-box;
  padding: 0 var(--v-offset-md);
  font: inherit;
  color: inherit;
  cursor: pointer;
  text-transform: uppercase;
  place-content: center;
  margin: 0;
  font-weight: 800;
  text-decoration: none;
  display: inline-grid;
}

.btn--green {
  background-image: linear-gradient(#00b147, #008e39);
  border-radius: 4px;
  height: 62px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

.btn--link {
  font-size: 14px;
  text-decoration: underline;
}

@media (hover: hover) and (pointer: fine) {
  .btn--link:hover {
    text-decoration: none;
  }
}

.maybe-nav {
  justify-content: flex-end;
  gap: 12px;
  font-size: 10px;
  line-height: 1;
  display: flex;
  transform: translateY(3px);
}

@media (width >= 992px) {
  .maybe-nav {
    font-size: 14px;
  }
}

.maybe-nav__item {
  align-items: center;
  gap: 4px;
  display: flex;
}

.maybe-nav svg {
  width: 24px;
  height: 24px;
}
/*# sourceMappingURL=index.c99b4373.css.map */
